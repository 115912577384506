<template>
  <history-list-card
    :title="title"
    :items="listItems"
    :loading="loading"
    :no-more-data="noMoreData"
    @click:back="handleBack"
    @infinite="loadMore"
    @onRangeChanged="handleRangeChanged"
  />
</template>

<script>
import { useNotifications } from '@/modules/notifications/compositions/notifications';
import { onMounted, toRefs } from '@vue/composition-api';

export default {
  name: 'HistoryNotificatoinList',
  props: {
    objectId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const {
      listItems,
      load,
      loadMore,
      fromDate,
      toDate,
      noMoreData,
      loading
    } = useNotifications(props.objectId);
    const handleBack = () => {
      emit('stack:back');
    };

    const handleRangeChanged = (from, to) => {
      fromDate.value = from;

      if (toDate) {
        toDate.value = to;
      }
    };

    onMounted(() => load());

    return {
      title: 'Notifications history',
      listItems,
      handleBack,
      loadMore,
      handleRangeChanged,
      noMoreData,
      loading
    };
  }
};
</script>

<style></style>
